<template>
  <v-row justify="end" class="mr-10">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('newspaper.addTitleFromFile') }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col>
                  <template>
                    <v-file-input
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      placeholder="Wybierz plik xlsx"
                      prepend-icon="mdi-newspaper-variant"
                      label="file"
                      v-model="file"
                    ></v-file-input>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">{{ $t('common.cancel') }}</v-btn>
          <v-btn color="blue darken-1" text @click="submit">{{ $t('common.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <spinner-dialog ref="SpinnerDialog"></spinner-dialog>
  </v-row>
</template>

<script>
import { displayInfo } from '@/utils/helpers'
import SpinnerDialog from '@/views/Spinner/SpinnerDialog'

export default {
  name: 'add-newspaper-from-file-dialog',
  components: {
    SpinnerDialog
  },
  data: () => ({
    dialog: false,
    file: null
  }),
  beforeMount () {
    this.init()
  },
  methods: {
    init () {
      this.newspaper = ''
    },
    async submit () {
      this.$refs.SpinnerDialog.dialog = true

      const formData = new FormData()
      formData.append('file', this.file)

      try {
        const result = await this.$http.post('/newspaper/AddFromFile', formData)

        if (result.status === 200) {
          this.$emit('submit')
          displayInfo(this.$t('newspaper.imported'))
          this.file = null
          this.dialog = false
        } else {
          displayInfo(this.$t('newspaper.errorDuringCreating'))
        }
      } catch (error) {

      }
      this.$refs.SpinnerDialog.dialog = false
    },
    close () {
      this.dialog = false
      this.file = null
    }
  }
}
</script>
