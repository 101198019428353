<template>
  <div>
    <v-card>
      <h2 class="pl-2">{{ $t('newspaper.title') }}</h2>
      <v-btn
        color="blue"
        class="float-right mr-10 white--text"
        @click.stop="addFromFile()">
        {{ $t('common.addFromFile') }}
        <v-icon class="ml-2 white--text">mdi-newspaper-variant</v-icon>
      </v-btn>
      <v-btn color="blue" class="float-right mr-10 white--text" @click.stop="addOrEdit()">
        {{ $t('common.add') }}
        <v-icon class="ml-2 white--text">mdi-newspaper-variant</v-icon>
      </v-btn>
      <div style="clear:both"></div>
      <v-data-table
        :headers="headers"
        :items="newspapers"
        :options.sync="options"
        :server-items-length="serverItemsLength"
      >
        <template
          slot="item.groups"
          slot-scope="props"
        >{{ props.item.groups.map(entry => entry.name).join(', ') }}</template>
        <template slot="item.activeFrom" slot-scope="props">{{ format_date(props.item.activeFrom) }}</template>
        <template slot="item.activeTo" slot-scope="props">{{ format_date(props.item.activeTo) }}</template>
        <template
          slot="item.stockingDate"
          slot-scope="props"
        >{{ format_date(props.item.stockingDate) }}</template>
        <template slot="item.actions" slot-scope="props" class="justify-center">
          <v-btn
            class="mx-2"
            icon
            @click="addOrEdit(props.item.id !== 0 ? JSON.parse(JSON.stringify(props.item)) : null)"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </v-btn>
          <v-btn class="mx-2" icon @click="deleteItem(props.item)">
            <v-icon dark>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <add-or-edit-newspaper-dialog ref="AddOrEditNewspaperDialog" @submit="getDataAsync"></add-or-edit-newspaper-dialog>

    <confirm-delete-dialog
      endPoint="/newspaper/Delete/"
      ref="confirmDeleteDialog"
      @submit="getDataAsync"
    ></confirm-delete-dialog>

    <add-newspaper-from-file-dialog ref="AddNewspaperFromFileDialog" @submit="getDataAsync"></add-newspaper-from-file-dialog>
  </div>
</template>
<script>
import AddOrEditNewspaperDialog from '@/views/Newspaper/AddOrEditNewspaperDialog'
import AddNewspaperFromFileDialog from '@/views/Newspaper/AddNewspaperFromFileDialog'
import ConfirmDeleteDialog from '@/components/Dialogs/ConfirmDeleteDialog'
import moment from 'moment'

export default {
  name: 'Newspapers',
  components: {
    AddOrEditNewspaperDialog,
    ConfirmDeleteDialog,
    AddNewspaperFromFileDialog
  },
  data () {
    return {
      headers: [
        { text: this.$t('newspaper.chainOfStores'), value: 'chainOfStores' },
        { text: this.$t('newspaper.typeOfTool'), value: 'typeOfTool' },
        { text: this.$t('newspaper.stockingDate'), value: 'stockingDate' },
        { text: this.$t('newspaper.activeFrom'), value: 'activeFrom' },
        { text: this.$t('newspaper.activeTo'), value: 'activeTo' },
        {
          text: this.$t('newspaper.promotedIceCream'),
          value: 'promotedIceCream'
        },
        { text: this.$t('newspaper.distributors'), value: 'distributors' },
        {
          text: this.$t('common.actions'),
          value: 'actions',
          width: '10%',
          sortable: false
        }
      ],
      newspapers: [],
      options: {},
      serverItemsLength: 0,
      endpoint: '/newspaper/get'
    }
  },
  methods: {
    async getDataAsync () {
      let url = this.endpoint

      if (this.options.page) {
        url += '?options.page=' +
          this.options.page +
          '&' +
          'options.itemsPerPage=' +
          this.options.itemsPerPage +
          '&options.sortBy=' + (this.options.sortBy.length > 0 ? this.options.sortBy[0] : 'activeFrom') +
          '&options.sortDesc=' + (this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : true)
      }

      const result = await this.$http.get(url)
      this.newspapers = result.data.results
      this.serverItemsLength = result.data.totalCount
    },
    format_date (value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
    deleteItem (item) {
      this.$refs.confirmDeleteDialog.dialog = true
      this.$refs.confirmDeleteDialog.useDeleteMethod = true
      this.$refs.confirmDeleteDialog.message = item.chainOfStores
      this.$refs.confirmDeleteDialog.id = item.id
    },
    addOrEdit (newspaper) {
      this.$refs.AddOrEditNewspaperDialog.dialog = true
      if (newspaper) {
        this.$refs.AddOrEditNewspaperDialog.newspaper = newspaper
      } else {
        this.$refs.AddOrEditNewspaperDialog.init()
      }
    },
    addFromFile () {
      this.$refs.AddNewspaperFromFileDialog.dialog = true
      this.$refs.AddNewspaperFromFileDialog.init()
    }
  },
  watch: {
    options: {
      handler () {
        this.getDataAsync()
      },
      deep: true
    }
  }
}
</script>
