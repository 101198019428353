<template>
  <v-row justify="end" class="mr-10">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
          >{{ newspaper.id === '' ? $t('newspaper.addTitle') :$t('newspaper.editTitle')}}</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Sieć sklepów"
                    v-model="newspaper.chainOfStores"
                    autocomplete="none"
                    :rules="[...rules.maxLengthTitle, ...rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Rodzaj narzędzia"
                    v-model="newspaper.typeOfTool"
                    :rules="[...rules.maxLengthTitle, ...rules.required]"
                    autocomplete="none"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    auto-grow
                    row-height="6px"
                    label="Promowane lody"
                    v-model="newspaper.promotedIceCream"
                    :rules="[...rules.maxLengthDescription, ...rules.required]"
                    autocomplete="none"
                  ></v-textarea>
                </v-col>
                <v-col cols="4">
                  <DatePickerInput
                    v-model="newspaper.stockingDate"
                    :labelText="'Data towarowania'"
                    :rules="rules.required"
                  ></DatePickerInput>
                </v-col>
                <v-col cols="4">
                  <DatePickerInput
                    v-model="newspaper.activeFrom"
                    :labelText="'Data od'"
                    :rules="rules.required"
                  ></DatePickerInput>
                </v-col>
                <v-col cols="4">
                  <DatePickerInput
                    v-model="newspaper.activeTo"
                    :labelText="'Data do'"
                    :rules="rules.required"
                  ></DatePickerInput>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    auto-grow
                    row-height="6px"
                    label="Dystrybutorzy"
                    v-model="newspaper.distributors"
                    :rules="[...rules.maxLengthDescription, ...rules.required]"
                    autocomplete="none"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false;">{{ $t('common.cancel') }}</v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="submit"
            :disabled="!valid"
          >{{ $t('common.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <spinner-dialog ref="SpinnerDialog"></spinner-dialog>
  </v-row>
</template>

<script>
import DatePickerInput from '@/components/DatePickerInput.vue'
import { displayInfo } from '@/utils/helpers'
import SpinnerDialog from '@/views/Spinner/SpinnerDialog'

export default {
  name: 'add-or-edit-newspaper-dialog',
  components: {
    DatePickerInput,
    SpinnerDialog
  },
  data: () => ({
    dialog: false,
    newspaper: null,
    rules: {
      required: [(v) => !!v || 'Pole wymagane'],
      maxLengthTitle: [
        (v) => v.length <= 256 || 'Maksymalna ilosć 256 znaków.'
      ],
      maxLengthDescription: [
        (v) => v.length <= 4096 || 'Maksymalna ilosć 4096 znaków.'
      ]
    },
    valid: true
  }),
  async created () {
    this.$watch('dialog', function (newVal) {
      if (newVal && this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    })
  },
  beforeMount () {
    this.init()
  },
  methods: {
    init () {
      this.newspaper = {
        id: '',
        chainOfStores: '',
        typeOfTool: '',
        promotedIceCream: '',
        activeFrom: '',
        activeTo: '',
        distributors: '',
        stockingDate: ''
      }
    },
    async submit () {
      this.$refs.SpinnerDialog.dialog = true

      try {
        this.$refs.form.validate()
        const isAdd = this.newspaper.id === ''
        let result
        if (isAdd) {
          result = await this.$http.post('/newspaper/add', this.newspaper)
        } else {
          result = await this.$http.put('/newspaper/update', this.newspaper)
        }
        if (result.status === 200) {
          if (isAdd) {
            this.$emit('submit')
            displayInfo(this.$t('newspaper.created'))
          }
          if (!isAdd) {
            this.$emit('submit')
            displayInfo(this.$t('newspaper.edited'))
          }
          this.dialog = false
        } else {
          displayInfo(this.$t('newspaper.errorDuringCreating'))
        }
      } catch (error) {
      }
      this.$refs.SpinnerDialog.dialog = false
    }
  }
}
</script>
